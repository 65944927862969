import './ProductCctv.css';
import './ProductCctv-Mobile.css';
import { useEffect } from 'react';
import {images, background} from './../../../../data/Images';

export const ProductCctv = () => {

    useEffect(() => {
        let root = document.getElementById('product-cctv-root');
        root.style.backgroundImage = "url("+background.patternPrimary+")";
    })

    return (
        <div id='product-cctv-root' className='product-cctv-root'>
            <div className='product-cctv-content'>
                <h1 className='textTitleBold'>Kami juga menyediakan perkhidmatan<br />pemasangan CCTV di Masjid/Surau Anda.</h1>
                <div className='product-cctv-content-item1'>
                    <div className='product-cctv-image'>
                        <img src={images.cctv1} />
                    </div>
                    <div className='product-cctv-text-container1'>
                        <h3>CCTV Untuk Masjid dan Surau</h3>
                        <p>Pemasangan cctv di masjid dan surau juga merupakan salah satu tindakan yang tepat daripada pihak ajk untuk mencegah dan menghidara perkara² yang tidak baik berlaku di persekitaran masjid dan surau.</p>
                    </div>
                </div>
                <div className='product-cctv-content-item2'>
                    <div className='product-cctv-text-container2'>
                        <h3>Video Live Khutbah/Kuliah</h3>
                        <p>Live video khutbah dan kuliah sudah menjadi kelaziman setiap masjid untuk mempraktikannya kerana ia dapat membantu jemaah² yang berada di belakang lebih menghayati teks yang disampaikan.</p>
                    </div>
                    <div id='product-cctv-img2' className='product-cctv-image'>
                        <img src={images.cctv2} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCctv;