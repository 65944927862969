import './css/App.css';
import { Routes, Route } from 'react-router-dom';
import { Nav, Footer, Home, Product, About, Faq, Contact, Locations } from './ui/pages';
import { useEffect, useRef } from 'react';
import routing from './data/Routing';
import socmed from './data/Socmed';

function App() {

  //const location = useLocation();
  const refNav = useRef();
  const refHome = useRef();
  const refLocation = useRef();
  let lastKnownPosition = 0;
  let ticking = false;
  var videoViewContainer = null;
  var videoPlayer = null;
  var locationDialog = null;

  // On Nav Changed
  function OnRouteChanged() {
    /*const currentPath = location.pathname;
    const arrMenu = ["/faq", "/about", "/contact", "/product"];
    if (arrMenu.includes(currentPath)) {
      refHome.current?.stopTimer();
    } else {
      refHome.current?.startTimer();
    }*/
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  // Scroll Listener
  function onScrollListener(v) {
    if (v > 28) {
      refNav.current.addShadow();
    } else {
      refNav.current.clearShadow();
    }
  }

  // Home Page
  const HomePage = () => {
    return (
      <Home
        ref={refHome}
        routeChanged={OnRouteChanged}
        onClickPlay={onClickPlayVideo}
        onClickMenuLocation={onClickMenuLocation}
        onScroll={(v) => onScrollListener(v)}
      />
    )
  }

  useEffect(() => {
    videoViewContainer = findView('app-video-container');
    videoPlayer = findView('app-video-player');
    videoViewContainer.onclick = () => {
      videoPlayer.src = "";
      display(videoViewContainer, "none");
      scrollable(true);
    }

    document.addEventListener('scroll', (event) => {
      //lastKnownPosition = event.target.scrollTop;
      lastKnownPosition = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          onScrollListener(lastKnownPosition);
          ticking = false;
        });
        ticking = true;
      }
    });

    locationDialog = findView('location-dialog');
    locationDialog.addEventListener('mousedown', event => {
      if (event.target.id == locationDialog.id) {
        onLocationClose();
      }
    })

  })

  function onClickPlayVideo() {
    display(videoViewContainer, "flex");
    scrollable(false);
    videoPlayer.src = socmed.youtube;
  }

  function onClickMenuLocation() {
    scrollable(false);
    display(locationDialog, "flex");
    refLocation.current?.requestGet();
  }

  function onLocationChanged(item) {
    refHome.current?.onLocationChanged(item);
  }

  function onLocationClose() {
    display(locationDialog, "none");
    scrollable(true);
  }

  function findView(id) {
    return document.getElementById(id);
  }

  function display(view, value) {
    view.style.display = value;
  }

  function scrollable(isScrollable) {
    document.body.style.overflow = isScrollable ? "auto" : "hidden";
  }

  return (
    <div id='root' className='app'>
      <Nav ref={refNav} />
      <Routes>
        <Route path={routing.other} element={<HomePage />} />
        <Route path={routing.home} element={<HomePage />} />
        <Route path={routing.product} element={
          <Product
            routeChanged={OnRouteChanged}
            onClickPlay={onClickPlayVideo} />
        } />
        <Route path={routing.about} element={
          <About
            routeChanged={OnRouteChanged}
            onScroll={(v) => onScrollListener(v)} />
        } />
        <Route path={routing.faq} element={<Faq routeChanged={OnRouteChanged} />} />
        <Route path={routing.contact} element={<Contact routeChanged={OnRouteChanged} />} />
      </Routes>
      <Footer />
      <div id='app-video-container' className='app-video-player-container'>
        <iframe id='app-video-player'
          className='app-video-player'
          title="jstv video promotion"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />
      </div>
      <div id='location-dialog' className='app-location-dialog'>
        <Locations
          ref={refLocation}
          onLocationChanged={(v) => onLocationChanged(v)}
          onLocationClose={onLocationClose} />
      </div>
    </div>
  );
}

export default App;
