import './LocationItem.css';
import { forwardRef } from 'react';
import ModelItemLocation from './../../../../model/ModelItemLocation';

export const LocationItem = forwardRef((props, ref) => {
    const Towns = () => {
        return props.item.towns.map((town, i) => {
            const townName = town.town.replace(/[0-9]/g, '');
            const item = new ModelItemLocation(town.state, town.town, town.code);
            return (
                <tr key={i} className='location-item-tr' onClick={() => {props.onClickItemLocation(item)}}>
                    <td>
                        <div className='location-item-container'>
                            <h3 className='textBold location-item-text-code'>{town.code}</h3>
                            <h3 className='text location-item-text-location'>{townName}</h3>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className='location-item-root'>
            <h3 className='textBold location-item-text-header'>{props.item.state}</h3>
            <table className='location-item-table'>
                <tbody>
                    <Towns />
                </tbody>
            </table>
        </div>
    )
});

export default LocationItem;