// Icons
const dirIcon = "./icons/";
const dirIconCustomers = dirIcon+"customers/";
// Images
const dirImg = "./img/";
const dirImgInstall = dirImg+"installation/";
const dirImgMosque = dirImg+"mosque/";
const dirImgUstadz = dirImg+"ustadz/";
// Background
const dirBackground = "./background/";

export const icons = {
    // Socmed
    facebook: dirIcon+"ic-fb.png",
    instagram: dirIcon+"ic-ig.png",
    whatsapp: dirIcon+"ic-wa.png",
    whatsapp2: dirIcon+"ic-wa2.png",
    icWhatsapp: dirIcon+"ic-wa.svg",
    icWhatsappPrimary: dirIcon+"ic-wa-primary.svg",
    // General
    copyright: dirIcon+"ic-copyright.png",
    copyrightWhite: dirIcon+"ic-copyright-white.png",
    telephone: dirIcon+"ic-telephone.png",
    phone: dirIcon+"ic-phone.png",
    icSearch: dirIcon+"ic-search.svg",
    icPrice: dirIcon+"ic-price-tag.svg",
    icPlay: dirIcon+"ic-play.svg",
    icPartner: dirIcon+"ic-partner.svg",
    icMore: dirIcon+"ic-more.svg",
    icMoreWhite: dirIcon+"ic-more-white.svg",
    icMoreReverse: dirIcon+"ic-more-reverse.svg",
    icMoreReverseWhite: dirIcon+"ic-more-reverse-white.svg",
    icMorePrimary: dirIcon+"ic-more-primary.svg",
    location: dirIcon+"ic-location.png",
    icLike: dirIcon+"ic-like.svg",
    icInstall: dirIcon+"ic-install.svg",
    icFeature: dirIcon+"ic-feature.svg",
    icEasy: dirIcon+"ic-easy.svg",
    icClose: dirIcon+"ic-close.svg",
    icCloseWhite: dirIcon+"ic-close-white.svg",
    icCheck: dirIcon+"ic-check.svg",
    icCalendar: dirIcon+"ic-calendar.svg",
    // Customers
    customer1: dirIconCustomers+"customer1.png",
    customer2: dirIconCustomers+"customer2.png",
    customer3: dirIconCustomers+"customer3.png",
    customer4: dirIconCustomers+"customer4.png",
    customer5: dirIconCustomers+"customer5.png",
    customer6: dirIconCustomers+"customer6.png",
    customer7: dirIconCustomers+"customer7.png",
}

export const images = {
    logo: "./logo-jstv.png",
    tvAndRemote: dirImg+"img-tv-remote-app.png",
    thumbnail: dirImg+"thumbnail.jpg",
    installation1: dirImgInstall+"installation1.jpg",
    installation2: dirImgInstall+"installation2.jpg",
    installation3: dirImgInstall+"installation3.jpg",
    installation4: dirImgInstall+"installation4.jpg",
    installation5: dirImgInstall+"installation5.jpg",
    // CCTV
    cctv1: dirImg+"cctv1.jpeg",
    cctv2: dirImg+"cctv2.jpg",
    // About
    aboutOffice: dirImg+"img-about-office.jpg",
    about1: dirImg+"img-about1.jpg",
    about2: dirImg+"img-about2.jpg",
    about3: dirImg+"img-about3.jpg",
    // Mosque
    mosque1: dirImgMosque+"mosque1.webp",
    mosque2: dirImgMosque+"mosque2.jpg",
    mosque3: dirImgMosque+"mosque3.jpg",
    // Ustadz
    ustadz1: dirImgUstadz+"ustadz1.jpeg",
    ustadz2: dirImgUstadz+"ustadz2.jpg",
    ustadz3: dirImgUstadz+"ustadz3.jpg",
}

export const background = {
    patternPage: dirBackground+"bg-pattern-page.png",
    patternPrice: dirBackground+"bg-pattern-price.png",
    patternPrimary: dirBackground+"bg-pattern-primary.png",
    patternSecondary: dirBackground+"bg-pattern-secondary.png",
}

export default {
    icons, images, background
};