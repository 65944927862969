import './Nav.css';
import socmed from './../../data/Socmed';
import { icons, images } from '../../data/Images';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

export const Nav = forwardRef((props, ref) => {

    var nav = null;
    var menu = null;
    var isShowShadow = false;

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        nav = document.getElementById('nav');
        menu = document.getElementById('menuList');
        const screenWidth = window.matchMedia("(max-width: 600px)")
        screenWidth.addEventListener("change", function () {
            if (!screenWidth.matches) {
                if (showMenu)
                    setShowMenu(false);
                menu.style.display = "flex";
            } else {
                menu.style.display = "none";
            }
        });

        if (showMenu) {
            nav.style.position = 'fixed';
            nav.style.left = '0';
            nav.style.top = '0';
            nav.style.right = '0';
            nav.style.bottom = '0';
            // Show Menu
            menu.style.display = "flex";
            setTimeout(() => {
                menu.className = 'showMenu';
            }, 100);
        } else {
            nav.style.position = "sticky";
        }
        document.body.style.overflow = showMenu ? "hidden" : "auto";
    })

    function addShadow() {
        if (!isShowShadow) {
            nav.className = 'nav-shadow';
            isShowShadow = true;
        }
    }

    function clearShadow() {
        if (isShowShadow) {
            nav.className = '';
            isShowShadow = false;
        }
    }

    useImperativeHandle(ref, () => {
        return { addShadow, clearShadow }
    })

    function toggleMenu() {
        if (showMenu) {
            menu.className = "hideMenu";
            setTimeout(() => {
                menu.style.display = "none";
                setShowMenu(!showMenu);
            }, 350);
        } else {
            setShowMenu(!showMenu);
        }
    }

    function onClickNavLink() {
        if (showMenu) {
            toggleMenu();
        }
    }

    function onClickSocmed(url) {
        window.open(url, '_blank');
    }

    return (
        <nav id='nav'>
            <Link to='/'>
                <img src={images.logo} className='title' />
            </Link>
            <div className='menu' onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul id='menuList'>
                <li><NavLink to='/' onClick={onClickNavLink}>Home</NavLink></li>
                <li><NavLink to='/product' onClick={onClickNavLink}>Product</NavLink></li>
                <li><NavLink to='/about' onClick={onClickNavLink}>Siapa Kami</NavLink></li>
                <li><NavLink to='/faq' onClick={onClickNavLink}>FAQ</NavLink></li>
                <li><NavLink to='/contact' onClick={onClickNavLink}>Hubungi Kami</NavLink></li>
                <div className='socmed'>
                    <img src={icons.facebook} onClick={() => onClickSocmed(socmed.facebook)} />
                    <img src={icons.whatsapp} onClick={() => onClickSocmed(socmed.whatsapp)} />
                    <img src={icons.instagram} onClick={() => onClickSocmed(socmed.instagram)} />
                </div>
                <div className='copyright-container'>
                    <img src={icons.copyright} />
                    <h3 className='textSemiBold'>2024 Jam Solat TV</h3>
                </div>
            </ul>
        </nav>
    )
})

export default Nav;