import './Footer.css';
import './Footer-Mobile.css';
import socmed from './../../data/Socmed';
import { icons, images, background } from './../../data/Images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import routing from './../../data/Routing';

export const Footer = () => {

    const route = useLocation();
    const navigate = useNavigate();

    function onClickMenu(value) {
        const currentPath = route.pathname;
        var menuPath = value == 2 ? routing.about : value == 3 ? routing.faq : value == 4 ? routing.contact : routing.home;
        if (currentPath != menuPath) {
            navigate(menuPath);
        }
    }

    function onClickSocmed(url) {
        window.open(url, '_blank');
    }

    useEffect(() => {
        bgImage('footer-top', background.patternPrimary);
        bgImage('footer-content', background.patternSecondary);
    })

    function bgImage(id, source) {
        let view = document.getElementById(id);
        view.style.backgroundImage = "url("+source+")";
    }

    return (
        <div className="flexV-center">
            <div id='footer-top' className='flexV-center footer-top'>
                <h2 className='textTitleBold'>Hubungi Kami Jika Anda Perlukan Maklumat Lanjut<br />dan Latihan Terus Kepada AJK Masjid/Surau</h2>
                <div className='flex-center footer-btn' onClick={() => onClickSocmed(socmed.whatsapp)}>
                    <img src={icons.whatsapp2} />
                    <h4 className='textBold'>Whatsapp Kami Di: 019-3910015</h4>
                </div>
            </div>
            <div id='footer-content' className='flexV-space footer-content'>
                <div></div>
                <div className='footer-bottom-container'>
                    <div className='footer-bottom'>
                        <div className='flex-center-space footer-bottom-top'>
                            <img src={images.logo} onClick={() => onClickMenu(1)}/>
                            <div className='flex-space'>
                                <h4 className='textBold' onClick={() => onClickMenu(2)}>Siapa Kami</h4>
                                <h4 className='textBold' onClick={() => onClickMenu(3)}>FAQ</h4>
                                <h4 className='textBold' onClick={() => onClickMenu(4)}>Hubungi Kami</h4>
                            </div>
                        </div>
                        <hr />
                        <div className='flex-space'>
                            <div className='flex-center'>
                                <img src={icons.copyrightWhite} className='footer-copyright-img' />
                                <h4 className='textTitleBold footer-copyright-text'>2024 Jam Solat TV</h4>
                            </div>
                            <div className='flex-center'>
                                <img
                                    src={icons.facebook}
                                    className='footer-socmed'
                                    onClick={() => onClickSocmed(socmed.facebook)}  />
                                <img
                                    src={icons.instagram}
                                    className='footer-socmed'
                                    onClick={() => onClickSocmed(socmed.instagram)} />
                                <img
                                    src={icons.whatsapp}
                                    className='footer-socmed'
                                    onClick={() => onClickSocmed(socmed.whatsapp)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;