export class ModelPrayerTime {

    constructor() {
        this._date = getCurrentDate();
        this._dateM = currentDate();
        this._code = "JOH01";
        this._location = "Pulau Aur dan Pulau Pemanggil";
        const defaultTime = "00:00 AM";
        const defaultTime2 = "00:00 PM";
        this._imsak = defaultTime;
        this._subuh = defaultTime;
        this._syuruk = defaultTime;
        this._zohor = defaultTime2;
        this._asar = defaultTime2;
        this._maghrib = defaultTime2;
        this._isyak = defaultTime2;
    }

    get date() { return this._date; }
    get dateM() { return this._dateM; }
    get code() { return this._code; }
    get location() { return this._location; }
    get imsak() { return this._imsak; }
    get subuh() { return this._subuh; }
    get syuruk() { return this._syuruk; }
    get zohor() { return this._zohor; }
    get asar() { return this._asar; }
    get maghrib() { return this._maghrib; }
    get isyak() { return this._isyak; }

    set date(value) { this._date = value +"<br/>"+getCurrentDate()}
    set code(value) { this._code = value; }
    set location(value) { this._location = value; }
    set imsak(value) { this._imsak = value; }
    set subuh(value) { this._subuh = value; }
    set syuruk(value) { this._syuruk = value; }
    set zohor(value) { this._zohor = value; }
    set asar(value) { this._asar = value; }
    set maghrib(value) { this._maghrib = value; }
    set isyak(value) { this._isyak = value; }

}

function getCurrentDate() {
    const date = new Date();  // yyyy-MM-dd
    return date.toLocaleString('ms-MY', {day: 'numeric', month:'long', year:'numeric'});
}

function currentDate() {
    return Date.now();
}

export default ModelPrayerTime;