import './Contact.css';
import './Contact-Mobile.css';
import { forwardRef, useEffect } from 'react';
import {icons, background} from './../../../data/Images';

export const Contact = forwardRef((props, ref) => {
    props.routeChanged();

    const mapStyle = {
        width: "100%",
        height: "100%",
        border: "0"
    };
    const mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448.63660174508027!2d101.72033645367712!3d3.206246340994081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc386e6aee2423%3A0xad766e57099d0729!2sAtista%20Group%20Sdn%20Bhd!5e0!3m2!1sid!2sid!4v1718094051614!5m2!1sid!2sid";

    useEffect(() => {
        let root = document.getElementById('contact-root');
        root.style.backgroundImage = "url("+background.patternPage+")";
    })

    return (
        <div id='contact-root' className='contact-root'>
            <div className='contact-content'>
                <h1 className='textHeader'>Hubungi Kami</h1>
                <div className='contact-content-container'>
                    <div className='contact-content-info'>
                        <h2 className='textTitleBold'>ATISTA GROUP SDN BHD (1241146-M)</h2>
                        <div className='contact-info-item'>
                            <img src={icons.location} />
                            <h3 className='text'>23A-3 Block A Platinum Walk No 2 Jalan Langkawi Danau Kota 53300 Setapak Kuala Lumpur</h3>
                        </div>
                        <div className='contact-info-item'>
                            <img src={icons.phone} />
                            <h3 className='text'><b>Tel Pejabat:</b> 03 41496886</h3>
                        </div>
                        <div className='contact-info-item'>
                            <img src={icons.telephone} />
                            <h3 className='text'><b>Hand Phone:</b> 019 3910015</h3>
                        </div>
                    </div>
                    <div className='contact-content-map'>
                        <iframe src={mapUrl}
                            title='google maps'
                            style={mapStyle}
                            allowFullScreen=""
                            loading="lazy"
                            aria-hidden="false"
                            referrerPolicy="no-referrer-when-downgrade"/>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Contact;