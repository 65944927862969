import './LocationPlaceholder.css';

export const LocationPlaceHolder = () => {

    const LocationPlaceHolderItem = () => {
        return (
            <div>
                <div className='shimmerBG title'></div>
                <div className='shimmerBG item'></div>
                <div className='shimmerBG item'></div>
                <div className='shimmerBG item'></div>
            </div>
        )
    }

    return (
        <div id='placeholder-parent'>
            <div className='shimmerBG search'></div>
            <LocationPlaceHolderItem />
            <LocationPlaceHolderItem />
            <LocationPlaceHolderItem />
            <LocationPlaceHolderItem />
            <LocationPlaceHolderItem />
            <LocationPlaceHolderItem />
        </div>
    )
}

export default LocationPlaceHolder;