import './PackagePrice.css';
import './PackagePrice-Mobile.css';
import { useEffect } from 'react';
import { icons, background } from './../../../../data/Images';
import socmed from './../../../../data/Socmed';

export const PackagePrice = () => {

    function onClickHaveItNow() {
        window.open(socmed.whatsapp, '_blank');
    }

    useEffect(() => {
        let root = document.getElementById('package-price-root');
        root.style.backgroundImage = "" + background.patternPrice + "";
    })

    const offers = [
        "Kerja-kerja membekal & memasang Jam Solat TV.",
        "Kerja-kerja membekal & memasang CCTV.",
        "Kerja-kerja membekal & memasang Live Streaming.",
        "Kerja-kerja membekal & memasang Projector."
    ];

    const Offers = () => {
        return offers.map((item, i) => {
            const id = "offer"+i;
            return(
                <li key={i} id={id}>{item}</li>
            )
        })
    } 

    return (
        <div id='package-price-root' className='package-price-root'>
            <div className='package-price-content'>
                <div className='flexV-center package-price-header'>
                    <h3 className='textBold'>Harga Pakej</h3>
                    <p className='text'>Pilih pakej yang bersesuaian dengan Masjid/Surau anda. Kami berharap dapat menjadikan hasrat Masjid/Surau anda menjadi kenyataan. Browse gambar pada permulaan page untuk maklumat lengkap tentang sistem dan harga yang ditawarkan.</p>
                </div>
                <div className='package-price-list'>
                    <div className='package-card'>
                        <h2>Penetapan harga berdasarkan:</h2>
                        <p className='text'>Pilih pakej yang bersesuaian dengan Masjid/Surau anda. Kami berharap dapat menjadikan hasrat Masjid/Surau anda menjadi kenyataan. Browse gambar pada permulaan page untuk maklumat lengkap tentang sistem dan harga yang ditawarkan.</p>
                        <h2>Pada asasnya pihak pengurusan Masjid/Surau perlu bermesyuarat dan menetapkan:</h2>
                        <ol>
                            <li>Jumlah TV yang ingin dipasang.</li>
                            <li>Saiz TV.</li>
                            <li>Lokasi TV di dalam Masjid/Surau.</li>
                        </ol>
                        <h2>Ketiga-tiga faktor diatas akan menentukan jenis pakej dan kos pemasangan sistem Jam Waktu Solat</h2>
                    </div>
                    <span>
                        <div className='package-card'>
                            <h2>Apa yang Kami tawarkan:</h2>
                            <ul>
                                <Offers />
                            </ul>
                            <div className='package-card-btn-container'>
                                <button className='btn2 btn2-primary' onClick={onClickHaveItNow}>
                                    <img src={icons.icWhatsappPrimary} />
                                    Miliki Sekarang
                                </button>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    )
}