import './ProductMain.css';
import './ProductMain-Mobile.css';
import { forwardRef, useEffect } from 'react';
import {icons, images} from './../../../../data/Images';

export const ProductMain = forwardRef((props, ref) => {
    useEffect(() => {
        const size = '(max-width: 599px)';
        const mediaQuery = window.matchMedia(size);
        if (mediaQuery.matches) {
            const fbFollower = document.getElementById('fb-follower');
            fbFollower.innerHTML = "Follower<br/>Facebook";

            const agentSeller = document.getElementById('agent-seller');
            agentSeller.innerHTML = "Agent<br/>Jualan";
        }
    })

    function onClickPlay() {
        props.onClickPlay();
    }

    return (
        <div className='product-main-root'>
            <h3 className='text'><b>Jam waktu solat TV</b>. Untuk kegunaan di Masjid, Surau, Sekolah, Pejabar & Premis Perniagaan, Pemasangan di seluruh Malaysia, Sabah, Sarawak, Brunei & Singapore.</h3>
            <div className='product-main-content'>
                <div className='product-main-video'>
                    <img src={images.thumbnail} className='product-main-thumbnail' />
                    <div className='product-main-video-info'>
                        <h2 className='textBold'>Jam Waktu Solat TV Android<br />Pertama di Malaysia</h2>
                        <img src={icons.icPlay} onClick={onClickPlay} />
                        <h6 className='textBold'>01:13</h6>
                    </div>
                </div>
                <div className='product-main-achievements'>
                    <div className='product-main-achievements-content'>
                        <div className='product-main-achievements-item'>
                            <div className='product-main-achievements-item-top'>
                                <img src={icons.icInstall} />
                                <h1 className='textBlack'>4,107</h1>
                            </div>
                            <h4 className='textBold'>Pemasangan</h4>
                        </div>
                        <div className='product-main-achievements-item'>
                            <div className='product-main-achievements-item-top'>
                                <img src={icons.icLike} />
                                <h1 className='textBlack'>19,587</h1>
                            </div>
                            <h4 id='fb-follower' className='textBold'>Follower Facebook</h4>
                        </div>
                        <div className='product-main-achievements-item'>
                            <div className='product-main-achievements-item-top'>
                                <img src={icons.icFeature} />
                                <h1 className='textBlack'>16</h1>
                            </div>
                            <h4 className='textBold'>Fungsi</h4>
                        </div>
                        <div className='product-main-achievements-item'>
                            <div className='product-main-achievements-item-top'>
                                <img src={icons.icPartner} />
                                <h1 className='textBlack'>35</h1>
                            </div>
                            <h4 id='agent-seller' className='textBold'>Agent Jualan</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ProductMain;