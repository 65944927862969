import './About.css';
import './About-Mobile.css';
import Customers from './../../customers/Customers';
import { forwardRef, useEffect } from 'react';
import {icons, images, background} from './../../../data/Images';

export const About = forwardRef((props, ref) => {
    props.routeChanged();

    useEffect(() => {
        let root = document.getElementById('about-root');
        root.style.backgroundImage = "url("+background.patternPage+")";
    })

    return (
        <div id='about-root' className='about-root'>
            <div className='about-content'>
                <h1 className='textHeader'>Siapa Kami</h1>
                <div className='about-img-header'>
                    <img src={images.aboutOffice} />
                </div>
                <p className='text about-p'><b>Atista Group Sdn Bhd</b> merupakan sebuah syarikat
                    Bumiputera Muslim sepenuhnya yang memasarkan produk-produk untuk kegunaan di
                    Masjid & Surau di seluruh Malaysia, Sabah, Sarawak, Brunei & Singapore.</p>
                <ul>
                    <li className='text'>Kami merupakan pembekal & pengeluaran produk Jam Solat TV (JSTV).</li>
                    <li className='text'>Kami juga menyediakan perkhidmatan membekal & pemasangan CCTV.</li>
                    <li className='text'>Kami juga menyediakan perkhidmatan membekal & pemasangan sistem Live Streaming.</li>
                    <li className='text'>Kami juga menyediakan perkhidmatan membekal & pemasangan Projector.</li>
                </ul>
                <div className="about-image-container">
                    <div className="about-grid-width grid-corner about-grid1">
                        <img src={images.installation5} />
                    </div>
                    <div className="about-grid-width grid-corner about-grid2">
                        <div></div>
                        <h2 className='textBold'>Kami memberikan khidmat servis selepas jualan yang berterusan</h2>
                    </div>
                    <div className="about-grid-width grid-corner about-grid3">
                        <img src={images.installation1} />
                    </div>

                    <div className="grid-corner about-grid4" >
                        <img src={images.installation2} />
                    </div>
                </div>
                <Customers />
            </div>
        </div>
    )
})

export default About;