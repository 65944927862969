import './Home.css';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { HomeMain, HomeSecond, PackagePrice, HomeLast, Customers } from '../../pages';
import { ModelPrayerTime } from './../../../model/ModelPrayerTime';
import { getPrayerTime } from './../../../api/api';
import Storage from './../../../data/Storage';
import Session from './../../../data/Session';
import socmed from './../../../data/Socmed';
import {icons} from './../../../data/Images';

export const Home = forwardRef((props, ref) => {
    props.routeChanged();
    const refLast = useRef();

    // Database
    const database = new Storage();
    var model = new ModelPrayerTime();
    if (database.getCode() == null) {
        database.setCode("JOH01");
    }
    model.code = database.getCode();

    if (database.getTown() == null) {
        database.setTown("Pulau Aur dan Pulau Pemanggil");
    }
    model.location = database.getTown();

    // Session
    const session = new Session();

    // Hijri Month
    const arrMonth = [
        "Muharram", "Safar", "RabiulAwal", "RabiulAkhir",
        "JamadilAwal", "JamadilAkhir", "Rejab", "Syaaban",
        "Ramadan", "Syawal", "Zulkaedah", "Zulhijjah"
    ];

    function requestGetPrayerTime() {
        getPrayerTime(model.code).then((result) => {
            const hijriDate = result.hijri_date;
            const hijriMonth = result.hijri_month;
            const hijriYear = result.hijri_year;
            let date = createDate(hijriDate, hijriMonth, hijriYear);
            model.date = date;
            session.setDate(date);

            const times = result.times;
            model.imsak = createTime(times.imsak);
            session.setImsak(model.imsak);

            model.subuh = createTime(times.subuh);
            session.setSubuh(model.subuh);

            model.syuruk = createTime(times.syuruq);
            session.setSyuruk(model.syuruk);

            model.zohor = createTime(times.zohor);
            session.setZohor(model.zohor);

            model.asar = createTime(times.asar);
            session.setAsar(model.asar);

            model.maghrib = createTime(times.maghrib);
            session.setMaghrib(model.maghrib);

            model.isyak = createTime(times.isak);
            session.setIsyak(model.isyak);

            refLast.current?.prayerData(model);
        })
    }

    function createDate(day, month, year) {
        return day + " " + arrMonth[month - 1] + " " + year;
    }

    function createTime(time) {
        const idxSeparator = time.indexOf(":");
        const hour = time.substring(0, idxSeparator);
        const minutes = time.substring(idxSeparator, time.length);

        const number = parseInt(hour);
        const finalHour = number < 10 ? "0" + number : "" + number;
        return finalHour + "" + minutes;
    }

    function onClickPlay() {
        props.onClickPlay();
    }

    function onClickHaveItNow() {
        window.open(socmed.whatsapp, '_blank');
    }

    function onNewDay() {
        requestGetPrayerTime();
    }

    function onLocationChanged(item) {
        database.setCode(item.code)
        database.setTown(item.town);
        model.location = item.town;
        model.code = item.code;
        setTimeout(() => {
            requestGetPrayerTime();
        }, 200);
    }

    function stopTimer() {
        refLast.current?.stopTimer();
    }

    useImperativeHandle(ref, () => {
        return { onLocationChanged, stopTimer }
    })

    function onClickMenuLocation() {
        props.onClickMenuLocation();
    }

    useEffect(() => {
        let sDate = session.getDate();
        if (sDate == null) {
            requestGetPrayerTime();
        } else {
            model.date = session.getDate();
            model.imsak = session.getImsak();
            model.subuh = session.getSubuh();
            model.syuruk = session.getSyuruk();
            model.zohor = session.getZohor();
            model.asar = session.getAsar();
            model.maghrib = session.getMaghrib();
            model.isyak = session.getIsyak();
            refLast.current?.prayerData(model);
        }
    }, []);

    return (
        <div className='home-root'>
            <HomeMain onClickPlay={onClickPlay} />
            <HomeSecond />
            <div className='home-feature-info'>
                <div className='home-feature-info-content'>
                    <h2 className='textBold'>SISTEM INI ADALAH INTEGRASI 16 TERAS PROGRAM IAITU</h2>
                    <p className='text'>Sistem ini juga memudahkan pengguna untuk menyampaikan maklumat semasa dan setempat. Penyampaian maklumat melalui kaedah ini dijangkakan akan memberikan lebih kesan dan mesra pengguna berbanding dengan kaedah yang sebelum ini digunakan.</p>
                </div>
                <button className='btn2' onClick={onClickHaveItNow}>
                    <img src={icons.icWhatsapp} />
                    Miliki Sekarang
                </button>
            </div>
            <PackagePrice />
            <HomeLast ref={refLast} onNewDay={onNewDay} onClickMenuLocation={onClickMenuLocation} />
            <div className='home-customer-container'>
                <Customers />
            </div>
        </div>
    )
})

export default Home;