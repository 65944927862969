import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import './HomeLast.css';
import Prayer from './prayer/Prayer';
import {images, background} from './../../../../data/Images';

export const HomeLast = forwardRef((props, ref) => {

    const refPrayer = useRef();

    function prayerData(value) {
        refPrayer.current?.prayerData(value);
    }

    function onNewDay() {
        props.onNewDay();
    }

    function stopTimer() {
        refPrayer.current?.stopTimer();
    }

    useEffect(() => {
        let prayer = document.getElementById('home-last-prayer');
        prayer.style.backgroundImage = "url("+background.patternPrimary+")";
    })

    useImperativeHandle(ref, () => {
        return { prayerData, stopTimer }
    })

    function onClickMenuLocation() {
        props.onClickMenuLocation();
    }

    return (
        <div className='home-last-root'>
            <div className='flex-space home-last-content'>
                <div className='home-last-info'>
                    <h2 className='textBold'>PERHATIAN</h2>
                    <p className='text'>Kesemua pakej yang disediakan tidak termasuk harga TV dan upah pemasangan atau pendawaian. Ini adalah kerana setiap Masjid/Surau memerlukan saiz TV dan jumlah TV yang berbeza.</p>
                    <p className='text'>Kami mencadangkan agar pihak Masjid atau Surau melakukan pemasangan TV terlebih dahulu oleh pakar letrik setempat kerana mereka lebih arif perihal infra yang sedia ada bagaimana pendawaian hendak dilakukan.</p>
                    <img src={images.tvAndRemote} />
                </div>
                <div id='home-last-prayer' className='flexV-space home-last-prayer'>
                    <Prayer
                        ref={refPrayer} onNewDay={onNewDay}
                        onClickMenuLocation={onClickMenuLocation} />
                </div>
                <div className='home-last-mosque'>
                    <img src={images.installation4} />
                </div>
            </div>
        </div>
    )
})

export default HomeLast;