import './ProductFeature.css';
import './ProductFeature-Mobile.css';
import features from './features';
import { useEffect } from 'react';
import {icons} from './../../../../data/Images';

export const ProductFeature = () => {

    var listView = null;
    var cardGap = 40;
    var paddingSide = 8;
    var gap = (cardGap + paddingSide) / 2;
    var scrollPos = 0;
    var allowScroll = true;

    let lastScroll = 0;

    useEffect(() => {
        const btnScrollLeft = findView('btnScrollLeft');
        const btnScrollRight = findView('btnScrollRight');
        visibility(btnScrollRight, "visible");
        listView = findView('feature-list');
        listView.onscroll = () => {
            allowScroll = false;
            lastScroll = listView.scrollLeft <= 0 ? 0 : listView.scrollLeft;
        }
        listView.onscrollend = (e) => {
            allowScroll = true;
            const lastScrollPos = listView.scrollLeft;
            const btnLeftVisibility = btnScrollLeft.style.visibility;
            if (lastScrollPos > 0) {
                if (btnLeftVisibility == "hidden") {
                    animation(btnScrollLeft, "showBtn 200ms ease-in");
                    visibility(btnScrollLeft, "visible");
                }
            } else {
                if (btnLeftVisibility == "visible") {
                    animation(btnScrollLeft, "hideBtn 200ms ease-out");
                    setTimeout(() => {
                        visibility(btnScrollLeft, "hidden");
                    }, 100);
                }
            }

            const btnRightVisibility = btnScrollRight.style.visibility;
            // End
            if (listView.scrollLeft + listView.offsetWidth >= listView.scrollWidth) {
                if (btnRightVisibility == "visible") {
                    animation(btnScrollRight, "hideBtn 200ms ease-out");
                    setTimeout(() => {
                        visibility(btnScrollRight, "hidden");
                    }, 200);
                }
            } else {
                if (btnRightVisibility == "hidden") {
                    animation(btnScrollRight, "showBtn 200ms ease-in");
                    visibility(btnScrollRight, "visible");
                }
            }
        }
        const listWidth = listView.getBoundingClientRect().width;
        scrollPos = listWidth + gap;
        visibility(btnScrollLeft, "hidden");
    })

    function findView(id) {
        return document.getElementById(id);
    }

    function visibility(view, value) {
        view.style.visibility = value;
    }

    function animation(view, anim) {
        view.style.animation = anim;
    }

    function onClickScrollLeft() {
        if (allowScroll)
            listView.scrollLeft -= scrollPos;
    }

    function onClickScrollRight() {
        if (allowScroll)
            listView.scrollLeft += scrollPos;
    }

    const Features = () => {
        return features.map((item, i) => {
            const number = i + 1;
            const id = "product-feature" + i;
            return (
                <div key={i} id={id} className='product-feature-card'>
                    <h2 className='textBold'>{number}</h2>
                    <div className='product-feature-card-content'>
                        <img src={item.icon} />
                        <h3 className='text'>{item.feature}</h3>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className='product-feature-root'>
            <h1 className='textTitleBold'>Jam Solat TV dengan 16 ciri utama<br />yang mudah digunakan dan mesra.</h1>
            <div className='product-feature-content'>
                <img
                    id='btnScrollLeft'
                    src={icons.icMoreReverse}
                    className='product-feature-btn-scroll'
                    onClick={onClickScrollLeft}
                />
                <div id='feature-list' className='product-feature-list'>
                    <Features />
                </div>
                <img
                    id='btnScrollRight'
                    src={icons.icMorePrimary}
                    className='product-feature-btn-scroll'
                    onClick={onClickScrollRight}
                />
            </div>
        </div>
    )
}

export default ProductFeature;