import {icons} from './../../../../data/Images';

export const features = [
    {
        icon: icons.icInstall,
        feature: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        icon: icons.icInstall,
        feature: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed.'
    },
    {
        icon: icons.icInstall,
        feature: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
    {
        icon: icons.icInstall,
        feature: 'This is feature description'
    },
];

export default features;