import {images} from './../../../../data/Images';

export const reviews = [
    {
        imgMosque: images.mosque1,
        title: 'Masjid UiTM Melaka, Melaka',
        rating: 4.5,
        comment: '“Paparan lebih kemas dan menarik berbanding software lain yang saya jumpa.”',
        imgProfile: images.ustadz1,
        name: 'Ustaz Fulan Fulan',
        mosqueName: 'Masjid UiTM Melaka',
        images: [
            images.installation2,
            images.installation3,
            images.installation5
        ]
    },
    {
        imgMosque: images.mosque2,
        title: 'Masjid UiTM Melaka, Melaka',
        rating: 4.0,
        comment: '“Paparan lebih kemas dan menarik berbanding software lain yang saya jumpa.”',
        imgProfile: images.ustadz2,
        name: 'Ustaz Fulan Fulan',
        mosqueName: 'Masjid UiTM Melaka',
        images: [
            images.installation5,
            images.installation3,
            images.installation2,
            images.installation5,
            images.installation3,
            images.installation2,
            images.installation5,
            images.installation3,
            images.installation2,
            images.installation5,
            images.installation3,
            images.installation2
        ]
    },
    {
        imgMosque: images.mosque3,
        title: 'Masjid UiTM Melaka, Melaka',
        rating: 3.8,
        comment: '“Paparan lebih kemas dan menarik berbanding software lain yang saya jumpa.”',
        imgProfile: images.ustadz3,
        name: 'Ustaz Fulan Fulan',
        mosqueName: 'Masjid UiTM Melaka',
        images: [
            images.installation3,
            images.installation5,
            images.installation2
        ]
    },
    {
        imgMosque: images.mosque1,
        title: 'Masjid UiTM Melaka, Melaka',
        rating: 4.5,
        comment: '“Paparan lebih kemas dan menarik berbanding software lain yang saya jumpa.”',
        imgProfile: images.ustadz1,
        name: 'Ustaz Fulan Fulan',
        mosqueName: 'Masjid UiTM Melaka',
        images: [
            images.installation2,
            images.installation3,
            images.installation5
        ]
    },
    {
        imgMosque: images.mosque2,
        title: 'Masjid UiTM Melaka, Melaka',
        rating: 4.0,
        comment: '“Paparan lebih kemas dan menarik berbanding software lain yang saya jumpa.”',
        imgProfile: images.ustadz2.jpg,
        name: 'Ustaz Fulan Fulan',
        mosqueName: 'Masjid UiTM Melaka',
        images: [
            images.installation5,
            images.installation3,
            images.installation2
        ]
    },
    {
        imgMosque: images.mosque3,
        title: 'Masjid UiTM Melaka, Melaka',
        rating: 3.8,
        comment: '“Paparan lebih kemas dan menarik berbanding software lain yang saya jumpa.”',
        imgProfile: images.ustadz3,
        name: 'Ustaz Fulan Fulan',
        mosqueName: 'Masjid UiTM Melaka',
        images: [
            images.installation3,
            images.installation5,
            images.installation2
        ]
    },
    {
        imgMosque: images.mosque1,
        title: 'Masjid UiTM Melaka, Melaka',
        rating: 4.5,
        comment: '“Paparan lebih kemas dan menarik berbanding software lain yang saya jumpa.”',
        imgProfile: images.ustadz1,
        name: 'Ustaz Fulan Fulan',
        mosqueName: 'Masjid UiTM Melaka',
        images: [
            images.installation2,
            images.installation3,
            images.installation5
        ]
    },
    {
        imgMosque: images.mosque2,
        title: 'Masjid UiTM Melaka, Melaka',
        rating: 4.0,
        comment: '“Paparan lebih kemas dan menarik berbanding software lain yang saya jumpa.”',
        imgProfile: images.ustadz2,
        name: 'Ustaz Fulan Fulan',
        mosqueName: 'Masjid UiTM Melaka',
        images: [
            images.installation5,
            images.installation3,
            images.installation2
        ]
    },
    {
        imgMosque: images.mosque3,
        title: 'Masjid UiTM Melaka, Melaka',
        rating: 3.8,
        comment: '“Paparan lebih kemas dan menarik berbanding software lain yang saya jumpa.”',
        imgProfile: images.ustadz3,
        name: 'Ustaz Fulan Fulan',
        mosqueName: 'Masjid UiTM Melaka',
        images: [
            images.installation3,
            images.installation5,
            images.installation2
        ]
    }
];

export default reviews;