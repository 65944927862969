import './Locations.css';
import { getLocations } from '../../../api/api';
import LocationItem from './item/LocationItem';
import LocationPlaceHolder from './placeholder/LocationPlaceholder';
import { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import {icons} from './../../../data/Images';

export const Locations = forwardRef((props, ref) => {

    const [locations, setLocations] = useState([]);
    const [locationsSearch, setLocationsSearch] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false);

    function requestGet() {
        if (!loaded) {
            setTimeout(() => {
                requestGetLocations();
            }, 1000);
        }
    }

    useImperativeHandle(ref, () => {
        return { requestGet }
    });

    useEffect(() => {
        if (loaded) {
            const searchView = document.getElementById('searchView');
            searchView.style.backgroundImage = "url("+icons.icSearch+")";
            searchView.oninput = () => {
                const query = searchView.value;
                if (query.length > 1) {
                    filter(query);
                    if (!searching) {
                        setSearching(true);
                    }
                } else {
                    if (searching) {
                        setSearching(false);
                    }
                }
            }
        }
    })

    function requestGetLocations() {
        getLocations().then((result) => {
            setLocations(result);
            locationLoaded();
        });
    }

    function locationLoaded() {
        setLoaded(true);
        setLoading(false);
    }

    function onClickItem(item) {
        hideDialog();
        props.onLocationChanged(item);
    }

    const ItemLocation = () => {
        return locations.map((item, i) => {
            return (
                <LocationItem id={"item" + i} key={i} item={item} onClickItemLocation={(v) => onClickItem(v)} />
            )
        })
    }

    const ItemLocationSearch = () => {
        return locationsSearch.map((item, i) => {
            return (
                <LocationItem id={"item" + i} key={i} item={item} onClickItemLocation={(v) => onClickItem(v)} />
            )
        })
    }

    const filter = (query) => {
        const mQuery = query.toLowerCase();
        setLocationsSearch(
            locations.filter(function (item) {
                const getState = item.state;
                const state = getState.toLowerCase();

                const getTowns = item.towns;
                var townSize = 0;
                getTowns.map((v, i) => {
                    const getTown = v.town;
                    const town = getTown.toLowerCase();
                    const found = town.includes(mQuery);
                    if (found) {
                        townSize = townSize + 1;
                    }
                })
                return townSize > 0 || state.includes(mQuery);
            })
        )
    }

    function hideDialog() {
        props.onLocationClose();
    }

    return (
        <div className="location-root">
            <div className='location-content'>
                <div className='location-header'>
                    <h1 className='textBold'>Pilih Lokasi</h1>
                    <img src={icons.icClose} onClick={hideDialog} />
                </div>
                <hr />
                {loading ? <LocationPlaceHolder /> :
                    <div className='location-list'>
                        <input
                            id='searchView'
                            className='location-searchView'
                            type='text'
                            name='searchview'
                            placeholder='Taip untuk mula carian...' />
                        {searching ? <ItemLocationSearch /> : <ItemLocation />}
                    </div>
                }
            </div>
        </div>
    )
})

export default Locations;