import './ProductReview.css';
import './ProductReview-Mobile.css';
import RatingView from './star/RatingView';
import reviews from './reviews';
import { forwardRef, useEffect } from 'react';
import { icons } from './../../../../data/Images';

export const ProductReview = forwardRef((props, ref) => {
    var listView = null;
    var cardGap = 30;
    var paddingSide = 8;
    var gap = (cardGap + paddingSide) / 2;
    var scrollPos = 0;
    var allowScroll = true;

    let lastScroll = 0;

    useEffect(() => {
        const btnScrollLeft = findView('btnScrollLeftReview');
        const btnScrollRight = findView('btnScrollRightReview');
        visibility(btnScrollRight, "visible");
        listView = findView('review-list');
        listView.onscroll = () => {
            allowScroll = false;
            lastScroll = listView.scrollLeft <= 0 ? 0 : listView.scrollLeft;
        }
        listView.onscrollend = (e) => {
            allowScroll = true;
            const lastScrollPos = listView.scrollLeft;
            const btnLeftVisibility = btnScrollLeft.style.visibility;
            if (lastScrollPos > 0) {
                if (btnLeftVisibility == "hidden") {
                    animation(btnScrollLeft, "showBtn 200ms ease-in");
                    visibility(btnScrollLeft, "visible");
                }
            } else {
                if (btnLeftVisibility == "visible") {
                    btnScrollLeft.style.animation = "hideBtn 200ms ease-out";
                    setTimeout(() => {
                        visibility(btnScrollLeft, "hidden");
                    }, 100);
                }
            }

            const btnRightVisibility = btnScrollRight.style.visibility;
            // End
            if (listView.scrollLeft + listView.offsetWidth >= listView.scrollWidth) {
                if (btnRightVisibility == "visible") {
                    animation(btnScrollRight, "hideBtn 200ms ease-out");
                    setTimeout(() => {
                        visibility(btnScrollRight, "hidden");
                    }, 200);
                }
            } else {
                if (btnRightVisibility == "hidden") {
                    animation(btnScrollRight, "showBtn 200ms ease-in");
                    visibility(btnScrollRight, "visible");
                }
            }
        }
        const listWidth = listView.getBoundingClientRect().width;
        scrollPos = listWidth + gap;
        visibility(btnScrollLeft, "hidden");
    })

    function findView(id) {
        return document.getElementById(id);
    }

    function visibility(view, value) {
        view.style.visibility = value;
    }

    function animation(view, anim) {
        view.style.animation = anim;
    }

    function onClickItem(value) {
        props.onClickReviewItem(value);
    }

    const ReviewItem = () => {
        return reviews.map((item, i) => {
            const number = i + 1;
            const id = "product-review" + i;
            return (
                <div key={i} id={id} className='review-card' onClick={() => onClickItem(item)}>
                    <div className='review-img-mosque-container'>
                        <img src={item.imgMosque} className='review-img-mosque' />
                        <div className='review-img-foreground'></div>
                    </div>
                    <div className='review-card-content'>
                        <h2 className='textBold'>Sistem Jam Solat TV di {item.title}</h2>
                        <RatingView id={i} rating={item.rating} />
                        <h4 className='text'>{item.comment}</h4>
                    </div>
                    <div className='review-item-bottom'>
                        <img src={item.imgProfile} />
                        <div className='review-item-bottom-text'>
                            <h5 className='textBold'>{item.name}</h5>
                            <h6 className='text'>{"Pentabdir " + item.mosqueName}</h6>
                        </div>
                    </div>
                </div>
            )
        });
    }

    function onClickScrollLeft() {
        if (allowScroll)
            listView.scrollLeft -= scrollPos;
    }

    function onClickScrollRight() {
        if (allowScroll)
            listView.scrollLeft += scrollPos;
    }

    return (
        <div className='product-review-root'>
            <h1 className='textTitleBold'>Testimoni dan Galeri</h1>
            <div className='product-review-content'>
                <img
                    id='btnScrollLeftReview'
                    src={icons.icMoreReverse}
                    className='product-review-btn-scroll'
                    onClick={onClickScrollLeft}
                />
                <div id='review-list' className='product-review-list'>
                    <ReviewItem />
                </div>
                <img
                    id='btnScrollRightReview'
                    src={icons.icMorePrimary}
                    className='product-review-btn-scroll'
                    onClick={onClickScrollRight}
                />
            </div>
        </div>
    )
})

export default ProductReview;