import './HomeSecond.css';
import './HomeSecond-Mobile.css';
import {icons, images} from './../../../../data/Images';

export const HomeSecond = () => {
    return (
        <div className='home-second-root'>
            <div className='home-second-content'>
                <div className='home-second-1st'>
                    <h2 className='textBold'>Jam Solat TV (JSTV)</h2>
                    <p className='text'><b>Jam Solat TV</b> atau JSTV adalah sebuah produk terkini keluaran Atista Group Sdn Bhd (1241146-M). Ia direka khas sebagai kesinambungan kepada usaha “berpesan-pesan kepada kebaikan” dalam arus modenisasi dan globalisasi ini. Maklumat dihujung jari diaplikasikan dengan menjadikan produk JSTV ini sebagai produk yang penting dan berkeutamaan tinggi untuk kegunaan masjid, surau atau mana-mana institusi .</p>
                    <h2 className='textBold'>Perusahaan Kami Terpercaya!</h2>
                    <div className='home-second-achievements'>
                        <div className='home-second-achievements-content'>
                            <div>
                                <img src={icons.icInstall} />
                                <h3 className='textBlack'>4,107</h3>
                                <h4 className='textBold'>Pemasangan</h4>
                            </div>
                            <div>
                                <img src={icons.icLike} />
                                <h3 className='textBlack'>19,587</h3>
                                <h4 className='textBold'>Follower<br />Facebook</h4>
                            </div>
                            <div>
                                <img src={icons.icFeature} />
                                <h3 className='textBlack'>16</h3>
                                <h4 className='textBold'>Fungsi</h4>
                            </div>
                            <div>
                                <img src={icons.icPartner} />
                                <h3 className='textBlack'>35</h3>
                                <h4 className='textBold'>Agent<br />Jualan</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='home-second-2nd'>
                    <div>
                        <img src={images.installation3} />
                    </div>
                    <div>
                        <img src={images.installation2} />
                    </div>
                </div>
            </div>
        </div>
    )
}