export const FaqData = [
    {
        question: "Bagaimana nak Update Waktu Solat?",
        answer: '<ol><li>Fail waktu solat baru akan dibekalkan dan waktu solat boleh dimasukkan dengan fungsi import yang disediakan.</li>' +
        '<li>Jika memerlukan onsite maintenance. Kami mengenakan charge yang minimum.</li>' +
        '<li>Onsite Maintenance melibatkan Pengurusan, Penghantaran, Pemasangan, Pengujian dan Latihan.</li>' +
        '<li>Anda akan memperolehi Jadual Waktu Solat baru, Takwim Islam baru dan Software Upgrade PERCUMA!</li></ol>'
    },
    {
        question: "Adakah TV perlu di ON 24x7?",
        answer: "<ol><li>TV boleh di ON 24x7. Jam solat TV Box yang dibekalkan adalah jenis SSD dan tidak mudah panas.</li>"+
        "<li>AJK Masjid/Surau boleh buat keputusan.</li>" +
        "<li>Boleh OFF TV sahaja menggunakan TV remote. Sistem akan berjalan seperti biasa, cuma tidak dipaparkan pada TV.</li>" +
        "<li>ON semula TV, sistem ia akan dipaparkan semula.</li>" +
        "<li>Jam Solat TV Box boleh dibiarkan ON 24x7 atau SHUTDOWN seperti PC biasa.</li></ol>"
    },
    {
        question: "Bagaimana nak kemaskini Slides?",
        answer: "<ol><li>Amat mudah. Sediakan slides yang ingin dikemaskini terlebih dahulu menggunakan komputer anda.</li>" +
        "<li>Copy fail dari Thumbdrive ke jamsolattv Box folder \"slider\".</li>" +
        "<li>Anda boleh masuk ke dalam Configuration secara automatik.</li></ol>"
    },
    {
        question: "Sistem memerlukan Internet?",
        answer: "<ol><li>TIDAK. Internet tidak diperlukan untuk sistem beroperasi.</li>" +
        "<li>Jika Masjid/Surau mempunyai internet, ia merupakan kelebihan.</li>" +
        "<li>Slides dapat dikemaskini dari remote.</li>" +
        "<li>Slides juga dapat menggunakan khidmat cloud seperti Dropbox, Google Drive atau One Drive.</li></ol>"
    },
    {
        question: "Apa yang diperlukan lagi?",
        answer: "<ol><li>Untuk mengemaskini SLIDER dan Pengumuman / Jadual Kuliah memerlukan Mouse dan Thumbdrive.</li>" +
        "<li>Keyboard (optional). Jam Solat TV Box mempunyai on screen keyboard.</li>" +
        "<li>Wireless Mouse dan Keyboard amat sesuai digunakan.</li></ol>"
    },
    {
        question: "Boleh kemaskini menggunakan Talipon?",
        answer: "<ol><li>Jika Masjid / Surau mempunyai Wireless LAN. Ianya boleh dilakukan menggunakan menggunakan aplikasi remote.</li>" +
        "<li>Kemahiran menggunakan komputer yang tinggi diperlukan.</li>" +
        "<li>Jam Solat TV Box menggunakan Sistem Android 10 dan mempunyai fungsi WiFi.</li>" +
        "<li>Remote desktop menggunakan komputer lebih mudah.</li></ol>"
    },
    {
        question: "Ada masalah display dan sound?",
        answer: "<ol><li>Tutup dulu Jam Solat TV.</li>" +
        "<li>Right mouse pada screen.</li>" +
        "<li>Select Graphics Options > Output To > Clone Displays > Digital Display + Digital TV</li></ol>"
    },
    {
        question: "Warranty and Support?",
        answer: "<p>Buat masa ini, Jam Solat TV Box yang dibekalkan mempunyai warranty selama 12 bulan untuk Box & waranty seumur hidup untuk sistem Jam Solat." +
        " Ianya mestilah tidak melibatkan kecuaian pengguna seperti</p>" +
        "<ol><li>Terkena air</li>" +
        "<li>Jatuh</li>" + 
        "<li>Dibuka dan seumpamanya</li></ol>" +
        "<p>Kami tidak menanggung kerosakan yang berpunca daripada</p>" +
        "<ol><li>Panahan petir</li>" +
        "<li>Bencana alam</li>" +
        "<li>\"Over voltage\"</li>" +
        "<li>Kecurian dan seumpamanya</li></ol>" +
        "<ul><li>Tidak termasuk on-site support dan kos penghantaran</li>" +
        "<li>Software Update PERCUMA. Waktu Solat Update PERCUMA</li>" +
        "<li>FREE to download</li>" +
        "<li>Tidak termasuk kos remote support atau on-site support</li><ul>"
    }
];