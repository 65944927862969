export class Storage {
    constructor() {
        this.STORAGE_NAME = 'com.jamsolattv.atista.my.';
        this.KEY_CODE = this.STORAGE_NAME + "code";
        this.KEY_TOWN = this.STORAGE_NAME + "town";
    }

    set(key, value) { localStorage.setItem(key, value); }
    get(key) { return localStorage.getItem(key); }

    // Code
    setCode(value) { this.set(this.KEY_CODE, value); }
    getCode() { return this.get(this.KEY_CODE);}

    // Town
    setTown(value) { this.set(this.KEY_TOWN, value); }
    getTown() { return this.get(this.KEY_TOWN); }
} 

export default Storage;