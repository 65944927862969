import { forwardRef, useEffect } from 'react';
import './RatingView.css';

export const RatingView = forwardRef((props, ref) => {
    const defaultId = "stars";
    useEffect(() => {
        const stars = document.getElementById(defaultId + props.id);
        stars.style.setProperty("--rating", props.rating);
    })
    return(
        <div id={defaultId + props.id} className='stars'></div>
    )
})

export default RatingView;