import './FaqItem.css';
import { forwardRef } from "react";
import {icons} from './../../../../data/Images';

export const FaqItem = forwardRef((props, ref) => {
    const question = props.question;
    const answer = props.answer;
    const index = props.index;
    var isExpand = false;

    function onClickItem() {
        setTimeout(() => {
            isExpand = !isExpand;
            const questionId = "question" + index;
            const questionView = document.getElementById(questionId);
            toggle(isExpand, questionView, 'faq-selected');

            const answerId = "faq-answer-container" + index;
            const answer = document.getElementById(answerId);
            toggle(isExpand, answer, 'faq-answer-container-open');
            
            const imgId = "question-image" + index;
            const imgMore = document.getElementById(imgId);
            toggle(isExpand, imgMore, "faq-item-question-arrow");
        }, 100);
    }

    function toggle(expand, el, name) {
        if (expand) {
            el.classList.add(name);
        } else {
            el.classList.remove(name);
        }
    }

    return (
        <div id='faq-item' className='faq-item'>
            <div id={"question" + index} className='faq-item-question' onClick={onClickItem}>
                <h3 className='textBold'>{question}</h3>
                <img id={"question-image" + index} src={icons.icMorePrimary} />
            </div>
            <div id={'faq-answer-container' + index} className='faq-answer-container'>
                <div id={"answer" + index} className='faq-answer' dangerouslySetInnerHTML={{ __html: answer }} />
            </div>
        </div>
    )
});