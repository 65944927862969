import './HomeMain.css';
import './HomeMain-Mobile.css';
import { forwardRef, useEffect } from 'react';
import {icons, images, background} from './../../../../data/Images';
import socmed from './../../../../data/Socmed';

export const HomeMain = forwardRef((props, ref) => {
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 599px)');
        if (mediaQuery.matches) {
            const desc = document.getElementById('home-main-desc');
            desc.innerHTML = "Untuk kegunaan di Masjid, Surau, Sekolah, Pejabat dan<br />Premis Perniagaan. Pemasangandiseluruh Malaysia,<br />Sabah, Sarawak, Brunei dan Singapura."
        }
        let root = document.getElementById('home-main-root');
        root.style.backgroundImage = "url("+background.patternPage+")";
    })

    function onClickHaveItNow() {
        window.open(socmed.whatsapp, '_blank');
    }

    function onClickPlay() {
        props.onClickPlay()
    }

    return (
        <div id='home-main-root' className='home-main-root'>
            <div className='home-main-content'>
                <div className='home-main-first'>
                    <h1 className='textHeader'>Pembekal Utama Sistem<br />Jam Waktu Solat<br />TV Android</h1>
                    <h2 className='textSemiBold'>Syarikat yang pertama di Malaysia<br />menggunakan Sistem Android</h2>
                    <h3 id='home-main-desc' className='text'>Untuk kegunaan di Masjid, Surau, Sekolah,<br />Pejabat dan Premis Perniagaan. Pemasangan<br />diseluruh Malaysia, Sabah, Sarawak, Brunei<br />dan Singapura.</h3>
                    <div className='home-main-first-btn-container'>
                        <button className='btn btn-primary' onClick={onClickHaveItNow}>
                            Miliki Sekarang
                            <img src={icons.icMoreWhite} />
                        </button>
                        <button className='btn' onClick={onClickPlay}>
                            Watch Product
                            <img src={icons.icMorePrimary} />
                        </button>
                    </div>
                </div>
                <div className='home-main-second'>
                    <div className='home-main-second-tv'>
                        <img src={images.tvAndRemote} />
                    </div>
                    <div className='home-main-second-tagline'>
                        <div className='home-main-second-tagline-item'>
                            <img src={icons.icEasy} />
                            <h3 className='textBold'>MUDAH</h3>
                        </div>
                        <div className='home-main-second-tagline-item'>
                            <img src={icons.icPrice} />
                            <h3 className='textBold'>MURAH</h3>
                        </div>
                        <div className='home-main-second-tagline-item'>
                            <img src={icons.icCalendar} />
                            <h3 className='textBold'>TERKINI</h3>
                        </div>
                    </div>
                    <div className='home-main-second-btn'>
                        <button className='btnSmall'>
                            Watch Demo
                            <img src={icons.icMorePrimary} />
                        </button>
                        <button className='btnSmall btn-primary'>
                            Miliki Sekarang
                            <img src={icons.icMoreWhite} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
})