import './Product.css';
import { forwardRef, useRef } from 'react';
import { ProductMain, ProductFeature, ProductCctv, ProductReview, ProductReviewDetail } from './../../pages';

export const Product = forwardRef((props, ref) => {
    props.routeChanged();
    const refDetail = useRef();

    function onClickPlayVideo() {
        props.onClickPlay();
    }

    function onClickReviewItem(value) {
        refDetail.current?.showDetail(value);
        scroll(false);
    }

    function onCloseDetail() {
        scroll(true);
    }

    function scroll(enable) {
        document.body.style.overflow = enable ? "auto" : "hidden";
    }

    return (
        <div className='product-root'>
            <h1 className='textHeader product-root-title'>Produk</h1>
            <ProductMain onClickPlay={onClickPlayVideo} />
            <ProductFeature />
            <ProductCctv />
            <ProductReview onClickReviewItem={(v) => onClickReviewItem(v)} />
            <ProductReviewDetail ref={refDetail} onCloseDetail={onCloseDetail} />
        </div>
    )
})

export default Product;