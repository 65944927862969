export class Session {

    #SESSION_NAME;
    #KEY_DATE;
    #KEY_IMSAK;
    #KEY_SUBUH;
    #KEY_SYURUK;
    #KEY_ZOHOR;
    #KEY_ASAR;
    #KEY_MAGHRIB;
    #KEY_ISYAK;
    
    constructor() {
        this.#SESSION_NAME = "com.jamsolattv.session.";
        this.#KEY_DATE = this.#SESSION_NAME+"date";
        this.#KEY_IMSAK = this.#SESSION_NAME+"imsak";
        this.#KEY_SUBUH = this.#SESSION_NAME+"subuh";
        this.#KEY_SYURUK = this.#SESSION_NAME+"syuruk";
        this.#KEY_ZOHOR = this.#SESSION_NAME+"zohor";
        this.#KEY_ASAR = this.#SESSION_NAME+"asar";
        this.#KEY_MAGHRIB = this.#SESSION_NAME+"maghrib";
        this.#KEY_ISYAK = this.#SESSION_NAME+"isyak";
    }

    #set(key, value) { sessionStorage.setItem(key, value) }
    #get(key) { return sessionStorage.getItem(key) }

    // Date
    setDate(value) { this.#set(this.#KEY_DATE, value); }
    getDate() { return this.#get(this.#KEY_DATE); }

    // Imsak
    setImsak(value) { this.#set(this.#KEY_IMSAK, value); }
    getImsak() { return this.#get(this.#KEY_IMSAK); }

    // Subuh
    setSubuh(value) { this.#set(this.#KEY_SUBUH, value); }
    getSubuh() { return this.#get(this.#KEY_SUBUH); }

    // Syuruk
    setSyuruk(value) { this.#set(this.#KEY_SYURUK, value); }
    getSyuruk() { return this.#get(this.#KEY_SYURUK); }

    // Zohor
    setZohor(value) { this.#set(this.#KEY_ZOHOR, value); }
    getZohor() { return this.#get(this.#KEY_ZOHOR); }

    // Asar
    setAsar(value) { this.#set(this.#KEY_ASAR, value); }
    getAsar() { return this.#get(this.#KEY_ASAR); }

    // Maghrib
    setMaghrib(value) { this.#set(this.#KEY_MAGHRIB, value); }
    getMaghrib() { return this.#get(this.#KEY_MAGHRIB); }

    // Isyak
    setIsyak(value) { this.#set(this.#KEY_ISYAK, value); }
    getIsyak() { return this.#get(this.#KEY_ISYAK); }

}

export default Session;