import { useEffect } from 'react';
import './Customers.css';
import {icons} from './../../data/Images';

export const Customers = () => {
    const customers = [
        icons.customer1, icons.customer2, icons.customer3,
        icons.customer4, icons.customer5, icons.customer6,
        icons.customer7
    ];

    useEffect(() => {
        const logos = findView('logos');
        const logos2 = logos.cloneNode(true);
        const root = findView('carousel');
        root.appendChild(logos2);
        setTimeout(() => {
            setAnimation(logos);
            setAnimation(logos2);
        }, 200);
    })

    function findView(id) {
        return document.getElementById(id);
    }

    function setAnimation(view) {
        view.style.animation = "scroll 35s infinite linear";
    }

    const ImageCustomers = () => {
        return customers.map((item, i) => {
            return(
                <img key={i} src={item} />
            )
        })
    }

    return (
        <div>
            <h3 className='textBold customer-text'>Pelanggan Kami</h3>
            <div id='carousel' className='carousel'>
                <div id='logos' className='carousel-content'>
                    <ImageCustomers />
                </div>
            </div>
        </div>
    )
}

export default Customers;