import './Prayer.css';
import './Prayer-Mobile.css';
import ModelPrayerTime from './../../../../../model/ModelPrayerTime';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

export const Prayer = forwardRef((props, ref) => {
    const model = useRef(new ModelPrayerTime());
    const arrPrayerTitleDefault = ["Imsak", "Subuh", "Syuruk", "Zohor", "Asar", "Maghrib", "Isyak"];
    const arrPrayerTitle = ["Imsak", "Subuh", "Syuruk", "Zohor", "Asar", "Maghrib", "Isyak"];
    arrPrayerTitle.reverse();
    var arrPrayerTime = [];
    var arrTimestamp = [];
    var txtDate = null;
    var timer = null;
    var goalsTime = "00:00 AM";
    var isNextDay = false;

    useEffect(() => {
        txtDate = findView('home-prayer-date');
        setText('date', defaultDate());
    })

    function prayerData(value) {
        setText('date', value.date);
        setText('imsak', value.imsak);
        setText('subuh', value.subuh);
        setText('syuruk', value.syuruk);
        setText('zohor', value.zohor);
        setText('asar', value.asar);
        setText('maghrib', value.maghrib);
        setText('isyak', value.isyak);

        model.current = value;
        arrPrayerTime = [];
        arrPrayerTime.push(value.imsak);
        arrPrayerTime.push(value.subuh);
        arrPrayerTime.push(value.syuruk);
        arrPrayerTime.push(value.zohor);
        arrPrayerTime.push(value.asar);
        arrPrayerTime.push(value.maghrib);
        arrPrayerTime.push(value.isyak);

        arrPrayerTime.reverse();

        checkTimePosition();
        setText('timeCode', model.current.code);
        setText('timeLocation', model.current.location.replace(/[0-9]/g, ''));
    }

    function checkTimePosition() {
        stopTimer();
        arrTimestamp = [];
        arrTimestamp.push(timestamp(model.current.imsak));
        arrTimestamp.push(timestamp(model.current.subuh));
        arrTimestamp.push(timestamp(model.current.syuruk));
        arrTimestamp.push(timestamp(model.current.zohor));
        arrTimestamp.push(timestamp(model.current.asar));
        arrTimestamp.push(timestamp(model.current.maghrib));
        arrTimestamp.push(timestamp(model.current.isyak));

        arrTimestamp.reverse();

        var idx = 0;
        const now = currentTimestamp();
        const timeSize = arrTimestamp.length - 1;
        for (const [i, e] of arrTimestamp.entries()) {
            idx = i;
            if (e <= now) {
                idx = i - 1;
                break;
            }
        }

        // Clear Selection
        for (const [i, e] of arrTimestamp.entries()) {
            const id = "home-prayer-item-" + arrPrayerTitle[i].toLowerCase();
            const prayerItem = findView(id);
            prayerItem.classList.remove('prayer-item-selected');
        }

        if (idx > -1) {
            const title = arrPrayerTitle[idx];
            goalsTime = arrPrayerTime[idx];
            setText('timeTitle', title);
            setText('timeTime', goalsTime);

            isNextDay = false;
            const id = "home-prayer-item-" + arrPrayerTitle[idx + 1].toLowerCase();
            const prayerItem = findView(id);
            prayerItem.classList.add('prayer-item-selected');
        } else {
            goalsTime = arrPrayerTime[6];
            setText('timeTitle', "Imsak");
            setText('timeTime', goalsTime);

            isNextDay = true;

            const id = "home-prayer-item-" + arrPrayerTitle[5].toLowerCase();
            const prayerItem = findView(id);
            prayerItem.classList.add('prayer-item-selected');
        }
        setTimeout(() => {
            startTimer();
        }, 200);
    }

    const time12To24 = (time12) => {
        const [time, modifier] = time12.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    }

    const timestamp = (time) => {
        const hour24 = time12To24(time);
        let [hours, minutes] = hour24.split(':');
        const newDate = new Date();
        newDate.setHours(hours, minutes, 0);
        return newDate.getTime();
    }

    const currentTimestamp = () => {
        return Date.now();
    }

    function startTimer() {
        stopTimer();
        if (arrPrayerTime.length > 0) {
            timer = setInterval(() => {
                count();
            }, 1000);
        }
    }

    function stopTimer() {
        if (timer != null)
            clearInterval(timer);
    }

    function count() {
        const now = Date.now();
        const hour24 = time12To24(goalsTime);
        let [hours, minutes] = hour24.split(':');

        const nextDate = new Date();
        if (isNextDay) {
            const today = new Date();
            nextDate.setDate(today.getDate() + 1);
        }
        nextDate.setHours(hours, minutes, 0);

        var remain = ((nextDate - now) / 1000);
        const hh = pad((remain / 60 / 60) % 60);
        const mm = pad((remain / 60) % 60);
        const ss = pad(remain % 60);
        const txtCounter = findView('home-prayer-timeCounter');
        if (txtCounter != null) {
            const txtSeconds = txtCounter.innerText.substring(16, 18)
            const sec = parseInt(txtSeconds);

            const isEnd = hh == 0 && mm == 0 && ss == 0;
            const missed = sec < 0 || sec < ss;

            if (isEnd || missed) {
                stopTimer();
                checkTimePosition();
            }
        } else {
            stopTimer();
        }

        const fullTime = hh + " jam " + mm + " minit " + ss + " saat<br/>lagi";
        setText('timeCounter', fullTime);

        const currentDay = new Date().getDate();
        onTick(currentDay);
    }

    function pad(num) {
        const numb = "0" + parseInt(num);
        const last = numb.length;
        return numb.substring(last - 2, last);
    }

    function onTick(currentDay) {
        const getTxtDate = txtDate.innerText;
        let length = getTxtDate.length;
        let idxStart = length - 11;
        const getDateOnly = getTxtDate.substring(idxStart, idxStart + 2);
        const findCurrentDate = parseInt(getDateOnly);
        if (findCurrentDate < currentDay) {
            if (window.navigator.onLine) {
                stopTimer();
                props.onNewDay();
            }
        }
    }

    function setText(id, value) {
        let view = findView("home-prayer-" + id);
        if (view != null) {
            view.innerHTML = value;
        }
    }

    function findView(id) {
        return document.getElementById(id);
    }

    function defaultDate() {
        const date = new Date();
        const dtMasehi = date.toLocaleString('ms-MY', { day: 'numeric', month: 'long', year: 'numeric' });
        return "00 xxxxxxxx 0000" + dtMasehi;
    }

    useImperativeHandle(ref, () => {
        return { prayerData, startTimer, stopTimer }
    });

    const HomePrayerItem = () => {
        return arrPrayerTitleDefault.map((item, i) => {
            const isEven = i % 2 == 1;
            const evenClass = isEven ? " prayer-item-even" : "";
            const time = i > 2 ? "00:00 PM" : "00:00 AM";
            const idItem = "home-prayer-item-" + arrPrayerTitleDefault[i].toLowerCase();
            const id = "home-prayer-" + arrPrayerTitleDefault[i].toLowerCase();
            return (
                <div key={i} id={idItem} className={"flex-space prayer-item" + evenClass}>
                    <h3 className='textBold'>{item}</h3>
                    <h3 id={id} className='textBold'>{time}</h3>
                </div>
            )
        })
    }

    function onClickMenuLocation() {
        props.onClickMenuLocation();
    }

    return (
        <div className='flexV-space prayer-root'>
            <div className='flex-space'>
                <div className='prayer-left'>
                    <h3>Waktu Seterusnya</h3>
                    <h3 id='home-prayer-timeTitle' className='prayer-left-prayer'>XXXXXX</h3>
                    <h3 id='home-prayer-timeTime' className='prayer-left-prayer-time'>XXXXX</h3>
                    <h3 id='home-prayer-date' className='prayer-left-date'>XX XXXXXXXX XXXX</h3>

                </div>
                <div className='prayer-right'>
                    <div className='flex-space'>
                        <div></div>
                        <button className='flex-space btn-zone' onClick={onClickMenuLocation}>
                            Tukar Zon
                            <div className='flexV-space prayer-menu'>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    </div>
                    <h3 id='home-prayer-timeCode' className='prayer-code'>XXXXX</h3>
                    <h3 id='home-prayer-timeLocation' className='prayer-location'>Kuala Lumpur, Putrajaya</h3>
                    <h3 id='home-prayer-timeCounter' className='prayer-counter'>00 jam 00 minit 00 saat<br />lagi</h3>
                </div>
            </div>
            <div>
                <HomePrayerItem />
                <h4>*Waktu Solat hanya bagi Semenanjung<br />Malaysia, Sabah dan Sarawak.</h4>
            </div>
        </div>
    )
})

export default Prayer;