import './ProductReviewDetail.css';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { icons } from './../../../../../data/Images';

export const ProductReviewDetail = forwardRef((props, ref) => {

    var root = null;
    var contentView = null;
    var txtTitle = null;
    var imgPreview = null;
    const itemId = "review-detail-img-";
    const itemSelected = "review-img-selected";
    var currentPosition = 0;
    const [images, setImages] = useState([]);

    useEffect(() => {
        root = findView('review-detail-root');
        contentView = findView('review-detail-content');
        txtTitle = findView('review-detail-title');
        imgPreview = findView('review-img-preview');
        if (images.length > 0)
            setPreview();
    }, [images]);

    function showDetail(value) {
        showBackdrop();
        if (txtTitle != null)
            txtTitle.innerText = "Sistem Jam Solat TV di " + value.title;
        setImages(value.images);
        setTimeout(() => {
            contentView.style.display = "flex";
            contentView.style.animation = "reviewContentShow 200ms ease-in forwards";
        }, 200);
    }

    function showBackdrop() {
        if (root != null) {
            const backdrop = root.style;
            backdrop.display = "flex";
            backdrop.animation = "backdropIn 200ms ease-in forwards";
        }
    }

    function hideBackdrop() {
        if (root != null) {
            const backdrop = root.style;
            setTimeout(() => {
                backdrop.animation = "backdropOut 200ms ease-out forwards";
                setTimeout(() => {
                    backdrop.display = "none";
                    props.onCloseDetail();
                }, 200);
            }, 200);
        }
    }

    function onClickClose() {
        if (contentView != null) {
            contentView.style.animation = "reviewContentHide 200ms ease-out forwards";
        }
        setTimeout(() => {
            contentView.style.display = "none";
            hideBackdrop();
        }, 200);
    }

    function onClickItem(idx) {
        clearSelection();
        currentPosition = idx;
        setPreview();
        select(idx);
    }

    function onClickNext() {
        if (currentPosition < (images.length - 1)) {
            clearSelection();
            currentPosition += 1;
            setPreview();
            select(currentPosition);
        }
    }

    function onClickPrevious() {
        if (currentPosition > 0) {
            clearSelection();
            currentPosition -= 1;
            setPreview();
            select(currentPosition);
        }
    }

    function setPreview() {
        if (imgPreview != null)
            imgPreview.src = images[currentPosition];
    }

    function select(idx) {
        let newItem = findView(itemId + idx);
        if (newItem != null) {
            newItem.classList.add(itemSelected);
            newItem.scrollIntoView();
        }
    }

    function clearSelection() {
        let currentItem = findView(itemId + currentPosition);
        currentItem.classList.remove(itemSelected);
    }

    function findView(id) {
        return document.getElementById(id);
    }

    useImperativeHandle(ref, () => {
        return { showDetail }
    })

    const Images = () => {
        return images.map((item, i) => {
            let isSelected = i == 0;
            let selected = isSelected ? itemSelected : "";
            let id = itemId + i;
            return (
                <img
                    key={i}
                    id={id}
                    src={item}
                    className={selected}
                    onClick={() => onClickItem(i)} />
            )
        })
    }

    return (
        <div id='review-detail-root' className='review-detail-root'>
            <div id='review-detail-content' className='review-detail-content'>
                <img
                    src={icons.icCloseWhite}
                    className='review-detail-close'
                    onClick={onClickClose} />
                <h2 id='review-detail-title' className='textBold'>Title</h2>
                <div className='review-detail-preview-container'>
                    <img
                        src={icons.icMoreReverseWhite}
                        className='review-detail-preview-btn'
                        onClick={onClickPrevious} />
                    <img
                        id='review-img-preview'
                        className='review-detail-preview' />
                    <img
                        src={icons.icMoreWhite}
                        className='review-detail-preview-btn'
                        onClick={onClickNext} />
                </div>
                <div className='review-detail-list'>
                    <Images />
                </div>
            </div>
        </div>
    )
})

export default ProductReviewDetail;