import './Faq.css';
import { forwardRef, useEffect, useState } from 'react';
import { FaqData } from './data/FaqData';
import { FaqItem } from './item/FaqItem';
import {icons, background} from './../../../data/Images';

export const Faq = forwardRef((props, ref) => {
    props.routeChanged();
    const [searching, setSearching] = useState(false);
    const [listSearch, setListSearch] = useState([]);

    useEffect(() => {
        let root = findView('faq-root');
        setBackground(root, background.patternPage);

        const searchView = findView('searchView');
        setBackground(searchView, icons.icSearch);
        searchView.oninput = () => {
            const query = searchView.value;
            if (query.length > 1) {
                filter(query);
                if (!searching) {
                    setSearching(true);
                }
            } else {
                if (searching) {
                    setSearching(false);
                }
            }
        }
    })

    function setBackground(view, source) {
        view.style.backgroundImage = "url("+source+")";
    }

    function findView(id) {
        return document.getElementById(id);
    }

    const filter = (query) => {
        const mQuery = query.toLowerCase();
        setListSearch(
            FaqData.filter(function (item) {
                const getQuestion = item.question;
                const question = getQuestion.toLowerCase();

                const getAnswer = item.answer;
                const answer = getAnswer.toLowerCase();
                return question.includes(mQuery) || answer.includes(mQuery);
            })
        );
    }

    const Items = () => {
        return FaqData.map((item, i) => {
            return (
                <FaqItem key={i} index={i} question={item.question} answer={"" + item.answer} />
            );
        });
    }

    const ItemsSearch = () => {
        return listSearch.map((item, i) => {
            return (
                <FaqItem key={i} index={i} question={item.question} answer={"" + item.answer} />
            );
        });
    }

    return (
        <div id='faq-root' className='faq-root'>
            <div className='faq-content'>
                <h1 className='textHeader'>Soalan Yang Kerap Ditanya</h1>
                <h2 className='text'>Apa yang boleh kami bantu?</h2>
                <input id='searchView' className='text'
                    type="text" name="search"
                    placeholder="Taip untuk mula carian..." />
                {
                    searching ? <ItemsSearch /> : <Items />
                }
            </div>
        </div>
    )
})

export default Faq;